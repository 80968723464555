import logo from '../../images/OnyxOnline+logo+text+2-compress.png';

const Title = () => {
  return (
    <div className='title'>
      <img src={logo} alt='logo'></img>
    </div>
  );
};

export default Title;
